var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"color":_vm.isDevelopment ? 'teal': 'white'}},[_c('span',{staticClass:"hidden-md-and-up"},[_c('v-app-bar-nav-icon',{on:{"click":_vm.sidebar}})],1),_vm._v(" "),_c('v-toolbar-title',{staticClass:"d-flex align-content-center"},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('img',{staticClass:"my-1",attrs:{"src":"/img/logo.svg","alt":"Інвентаризація Острозька академія","width":"50px"}})]),_vm._v(" "),_c('v-list-item-title',[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":{ name: 'dashboard' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticClass:"text-h5",attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.appName))])]}}])})],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-items',{staticClass:"d-none d-md-flex"},[_vm._l((_vm.menuItems),function(item){return _c('v-btn',{key:item.title,attrs:{"text":"","to":item.path}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(_vm._s(item.icon))]),_vm._v("\n            "+_vm._s(item.title)+"\n        ")],1)}),_vm._v(" "),(_vm.settingsMenuItems.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-cog")]),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,false,3501627290)},[_vm._v(" "),_c('v-list',_vm._l((_vm.settingsMenuItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e(),_vm._v(" "),(_vm.user)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"text":""}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.user.avatar}})],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"hidden-md-and-down d-lg-block"},[_c('v-list-item-title',{staticClass:"text-subtitle-1"},[_vm._v("\n                            "+_vm._s(_vm.user.name)+"\n                        ")]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("\n                            "+_vm._s(_vm.user.email)+"\n                            "),_c('v-chip',{attrs:{"color":"green","text-color":"white","label":"","x-small":""}},[_vm._v("\n                                "+_vm._s(_vm.user.role.name)+"\n                            ")])],1)],1)],1)]}}],null,false,1552453768)},[_vm._v(" "),_c('v-list',[_vm._l((_vm.profileMenuItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),_vm._v(" "),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("\n                            mdi-logout\n                        ")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v("Вийти")])],1)],2)],1):[_c('v-btn',{attrs:{"text":""}},[_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("\n                    Залогінся\n                ")])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }